import * as React from "react"
import styled from "styled-components"
import { up } from "styled-breakpoints"

import { space } from "../../theme"

import EventCard from "./EventCard"
import HeroPost from "./HeroPost"

import { Wrapper } from "./components"
import { EventProps } from "./utils"

export type Props = {
  data: EventProps[]
}

const SuperContent = styled.div`
  display: grid;
  position: relative;
`

const Container = styled.section`
  position: relative;
  top: -2rem;
  margin-bottom: -2rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: row;
  grid-gap: ${space("normal")};
  padding: ${space("large")} 0;
  ${up("tablet")} {
    padding: ${space("large")} ${space("small")};
    grid-template-columns: repeat(2, 1fr);
  }
  ${up("desktop")} {
    top: -10rem;
    margin-bottom: -10rem;
    grid-template-columns: repeat(3, 1fr);
  }
`

const EventList: React.FC<Props> = ({ data: [first, ...data] }) => {
  return (
    <SuperContent>
      <HeroPost {...first} />
      <Wrapper>
        <Container>
          {data.map((entry, idx) => (
            <EventCard key={`blog-entry-${idx}`} {...entry} index={idx} />
          ))}
        </Container>
      </Wrapper>
    </SuperContent>
  )
}

export default EventList
