import * as React from "react"
import styled from "styled-components"
import { up } from "styled-breakpoints"

import { getBg, color, space } from "../../theme"
import Text from "../../components/text"
import Img from "../../components/img"
import { SimpleTextLink, ArrowLink } from "../../components/link"

import {
  Wrapper,
  CardContent,
  CardVPipe,
  CardHPipe,
  Excerpt,
} from "./components"
import { EventProps, getC, randomnumber } from "./utils"

const HeroSuperContent = styled.div`
  display: grid;
  position: relative;
  padding: 0;
  ${up("desktop")} {
    padding: ${space("large")} 0;
    padding-bottom: 10rem;
  }
`

const HeroContainer = styled.div<{ bg: ColorKeys }>`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${space("normal")};
  padding: ${space("small")};
  background-color: ${getBg};
  ${up("tablet")} {
    padding: ${space("normal")};
  }
  ${up("desktop")} {
    padding: ${space("large")};
    grid-template-columns: 1fr auto;
  }
`
const HeroTitle = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${space("small")};
  align-items: center;
  justify-items: flex-start;
  padding: ${space("large")} ${space("normal")};
  ${up("desktop")} {
    grid-template-columns: auto 1fr;
    padding: ${space("large")} 0;
  }
`

const HeroBG = styled.div<{ bg: ColorKeys }>`
  display: none;
  z-index: 0;
  position: absolute;
  top: 0;
  right: 0;
  width: 40%;
  height: 100%;
  background-color: ${p => color(p.bg)};
  ${up("desktop")} {
    display: block;
  }
`

const HeroImg = styled(Img)`
  grid-column: 1;
  grid-row: 1;
  z-index: 1;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  ${up("desktop")} {
    grid-column: 2;
    grid-row: 1;
  }
`

const BG = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: ${color("yellow")};
  z-index: -1;
`

const HeroPipeV = styled(CardVPipe)`
  display: none;
  ${up("desktop")} {
    display: block;
  }
`
const HeroPipeH = styled(CardHPipe)`
  display: none;
  ${up("desktop")} {
    display: block;
  }
`

const WorkshopLogo = styled(Img)`
  margin: 0;
`

const HeroPost: React.FC<EventProps> = ({
  slug,
  title,
  excerpt,
  published,
  image,
  // registerLink,
}) => {
  const [c, cc, ccc] = getC(0)
  return (
    <HeroSuperContent>
      <BG />
      <Wrapper>
        <HeroTitle>
          <WorkshopLogo
            src="/workshop-logo.svg"
            alt="workshop logo"
            mw={"100%"}
            mh={"auto"}
            tw={250}
            th={"auto"}
            fit="contain"
          />
          <div>
            <Text type="headline">The Founders' Workshop</Text>
            <Text type="h4">
              For businesses designing the economies of tomorrow
            </Text>
          </div>
        </HeroTitle>
        <HeroContainer bg="white">
          <HeroBG bg={c} />
          <HeroPipeV x={randomnumber(50, 280)} c={cc} />
          <HeroPipeH y={randomnumber(60, 280)} c={ccc} isLeft={false} />
          <CardContent>
            <SimpleTextLink
              to={`/${slug}`}
              type="h5"
              align="left"
              color={c}
              hoverColor="black"
            >
              {title}
            </SimpleTextLink>
            <Excerpt>{excerpt}</Excerpt>
            <Text color="grey" type="mobileBody">
              {new Date(published).toLocaleDateString("en-GB", {
                weekday: "short",
                year: "numeric",
                month: "short",
                day: "numeric",
              })}
            </Text>
            <ArrowLink to={`/${slug}`} color={c} />
          </CardContent>
          <HeroImg
            src={image.src}
            alt={image.alt}
            mw={"100%"}
            mh={"auto"}
            tw={"100%"}
            th={"350px"}
            dw={"680px"}
            dh={"auto"}
            fit="cover"
          />
        </HeroContainer>
      </Wrapper>
    </HeroSuperContent>
  )
}

export default HeroPost
