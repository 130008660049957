import * as React from "react"
import styled from "styled-components"
import { up } from "styled-breakpoints"

import { color, space } from "../../theme"
import Text from "../../components/text"
import Img from "../../components/img"
import { SimpleTextLink, ArrowLink } from "../../components/link"

import { CardContent, CardVPipe, CardHPipe, Excerpt } from "./components"
import { EventProps, getC, randomnumber } from "./utils"

const Card = styled.section`
  position: relative;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-gap: ${space("small")};
  align-items: stretch;
  justify-items: flex-start;
`
const CardImg = styled(Img)`
  position: relative;
  top: 13px;
  margin: 0 auto;
  margin-bottom: 40px;
  img {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
  ${up("tablet")} {
    top: 12px;
  }
  ${up("desktop")} {
    top: 10px;
  }
`
const CardBG = styled.div<{ bg: ColorKeys }>`
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 250px;
  background-color: ${p => color(p.bg)};
`

const EventCard: React.FC<EventProps & { index: number }> = ({
  slug,
  title,
  excerpt,
  published,
  image,
  // registerLink,
  index,
}) => {
  const [c, cc, ccc] = getC(index)
  return (
    <Card>
      <CardBG bg={c} />
      <CardVPipe x={randomnumber(50, 280)} c={cc} />
      <CardHPipe
        y={randomnumber(50, 180)}
        c={ccc}
        isLeft={randomnumber(0, 100) % 2 === 0}
      />
      <CardImg
        src={{
          default: image.src,
          mobile: `${image.src}?fit=crop&h=300&w=240`,
          tablet: `${image.src}?fit=crop&h=250&w=350`,
          desktop: `${image.src}?fit=crop&h=250&w=350`,
        }}
        alt={image.alt}
        mw={"85%"}
        mh={"240px"}
        tw={"85%"}
        th={"250px"}
        dw="90%"
        dh="250px"
        fit="cover"
      />
      <CardContent>
        <SimpleTextLink
          to={`/${slug}`}
          type="h5"
          align="left"
          color={c}
          hoverColor="black"
        >
          {title}
        </SimpleTextLink>
        <Excerpt>{excerpt}</Excerpt>
        <Text color="grey" type="mobileBody">
          {new Date(published).toLocaleDateString("en-GB", {
            weekday: "short",
            year: "numeric",
            month: "short",
            day: "numeric",
          })}
        </Text>
        <ArrowLink to={`/${slug}`} color={c} hoverColor={cc} />
      </CardContent>
    </Card>
  )
}

export default EventCard
