import React from "react"
import { Helmet } from "react-helmet"

import { useStaticQuery, graphql } from "gatsby"

import EventList from "../blocks/Event/EventList"
import Layout from "../components/layout"

const BlogPage = () => {
  const { allEvent } = useStaticQuery(
    graphql`
      query {
        allEvent(sort: { order: DESC, fields: published }) {
          totalCount
          nodes {
            title
            slug
            published
            excerpt
            image {
              src
              alt
            }
            registerLink {
              to
              copy
            }
          }
        }
      }
    `
  )
  return (
    <Layout>
      <Helmet>
        <title>Paybase - Blog Listing</title>
        <meta property="og:title" content="Paybase - Event Listing" />
      </Helmet>
      <EventList data={allEvent.nodes} />
    </Layout>
  )
}

export default BlogPage
